<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <logo />
    </b-link>

    <div class="container mt-5 pt-3 mx-md-5">
      <b-overlay
        :show="showOverlay"
        rounded="sm"
        variant="dark"
      >
        <div>
          <daas-form
            :show-header="true"
            :customer-daas="true"
          />
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  BLink,
  BOverlay,
} from 'bootstrap-vue'
import { heightFade } from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
import Logo from '@core/layouts/components/Logo.vue'
import DaasForm from '@/components/DaasForm.vue'

export default {
  components: {
    Logo,
    BLink,
    DaasForm,
    BOverlay,
  },

  directives: {
    heightFade,
    Ripple,
  },

  data() {
    return {
      showOverlay: false,
    }
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/page-misc.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
